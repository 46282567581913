<template>
  <div v-if="isShow">
    <loading/>
  </div>
  <div class="common-bg">
    <div class="middle">
       <h1 class="common-h1">聯絡我們</h1>

      <div class="contact">
        <div class="contact-left">
          <h3>聯絡信息</h3>
          <div class="contact-info">
            <div class="contact-info-li">
               <div class="info-icon">
                 <i class="iconfont icon-whatsapp"></i>
               </div>
              <div class="info-txt">
                <p>WhatsApp</p>
                <p><b>{{ info.whats_app }}</b></p>
              </div>
            </div>

            <div class="contact-info-li">
              <div class="info-icon">
                <i class="iconfont icon-wechat"></i>
              </div>
              <div class="info-txt">
                <p>WeChat</p>
                <p><b>{{ info.we_chat }}</b></p>
              </div>
            </div>

            <div class="contact-info-li">
              <div class="info-icon">
                <i class="iconfont icon-email-fill"></i>
              </div>
              <div class="info-txt">
                <p>Email</p>
                <p><b>{{ info.email }}</b></p>
              </div>
            </div>

            <div class="contact-info-li">
              <div class="info-icon">
                <i class="iconfont icon-telephone-f"></i>
              </div>
              <div class="info-txt">
                <p>辦公電話</p>
                <p><b>{{ info.phone }}</b></p>
              </div>
            </div>

            <div class="contact-info-li">
              <div class="info-icon">
                <i class="iconfont icon-address"></i>
              </div>
              <div class="info-txt">
                <p>地址</p>
                <p><b>{{ info.address }}</b></p>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-left contact-right">
          <h3>咨詢信息</h3>
          <div class="contact-info">
               <Form></Form>
          </div>
        </div>
      </div>

        <div class="map common-padding-half">
          <iframe :src="info.address_url" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style="border: 0px;"></iframe>
        </div>

    </div>
   </div>
</template>

<script>
import Loading from "@/components/loading";
import Form from "@/components/form";
import {getContactInfo} from "@/network/main";

export default {
  name: "Contact",
  components:{
    Form,
    Loading
  },
  data(){
    return{
      info:'',
      isShow:true
    }
  },
  created() {
    this.getContactInfo()
  },
  methods:{
    getContactInfo() {
      getContactInfo().then(res =>{
        this.isShow = false;
        this.info = res.data.data;
      })
    },
  }
}
</script>

<style scoped>

</style>